import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Resume = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Resume" />
    <iframe
      style={{
        display: "block",
        width: "836px",
        height: "1043px",
        marginBottom: "20px",
      }}
      src="/resume.pdf"
    ></iframe>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Resume
